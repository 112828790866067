import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { Link } from "../../atoms/link/link";
import { LinkType } from "../../atoms/link/link.types";
import { type SitemapProps } from "./sitemap.types";

export function Sitemap({ links }: SitemapProps) {
	return (
		<div className="max-content mx-auto">
			<div className="px-4 max-w-full mb-12 lg:mb-16 lg:px-0 prose-h2:mb-2 prose-h2:mt-4 prose-h2:md:mb-4 prose-h2:md:mt-4">
				<Link destination="/" linkType={LinkType.Default}>
					Home
				</Link>

				{links
					.filter((link) => link.slug.length === 1)
					.map((firstLevelLink, key) => (
						<div key={key}>
							{links.filter(
								(secondLevelLink) =>
									secondLevelLink.slug[0] === firstLevelLink.slug[0]
							).length > 1 ? (
								// If there are second level links, render a list with a header
								<div>
									<Heading level={HeadingLevel.H2}>
										{firstLevelLink.title}
									</Heading>
									<ul className="list-none mb-0">
										{/* Link with same name as header */}
										<li className="my-2">
											<Link
												linkType={LinkType.Default}
												destination={firstLevelLink.slug.join("/")}
											>
												{firstLevelLink.title}
											</Link>
										</li>
										{links
											.filter(
												(secondLevelLink) =>
													secondLevelLink.slug[0] === firstLevelLink.slug[0] &&
													secondLevelLink.slug !== firstLevelLink.slug &&
													secondLevelLink.slug.length === 2
											)
											.map((secondLevelLink, key) => (
												<li className="my-2" key={key}>
													<Link
														linkType={LinkType.Default}
														destination={secondLevelLink.slug.join("/")}
													>
														{secondLevelLink.title}
													</Link>
													{links.filter(
														(link) =>
															link.slug[0] === secondLevelLink.slug[0] &&
															link.slug[1] === secondLevelLink.slug[1] &&
															link.slug !== secondLevelLink.slug
													).length > 1 ? (
														<ul className="list-none mb-0">
															{links
																.filter(
																	(link) =>
																		link.slug[0] === secondLevelLink.slug[0] &&
																		link.slug[1] === secondLevelLink.slug[1] &&
																		link.slug !== secondLevelLink.slug
																)
																.map((thirdLevelLink, key) => (
																	<li className="pl-6" key={key}>
																		<Link
																			linkType={LinkType.Default}
																			destination={thirdLevelLink.slug.join(
																				"/"
																			)}
																		>
																			{thirdLevelLink.title}
																		</Link>
																	</li>
																))}
														</ul>
													) : null}
												</li>
											))}
									</ul>
								</div>
							) : (
								// If there are no second level links, render a single link
								<div className="border-t border-grey-30 pt-2 mt-4">
									<Link
										linkType={LinkType.Default}
										destination={firstLevelLink.slug.join("/")}
									>
										{firstLevelLink.title}
									</Link>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	);
}
