import React from "react";
import { RichText as RichTextBrc } from "@britishredcross/component-library/src/components/atoms/rich-text/rich-text";
import {
	type PortableTextExternalLink,
	nodeParse,
	transformToPortableText,
} from "@kontent-ai/rich-text-resolver";
import {
	toHTML,
	type PortableTextMarkComponentOptions,
	type PortableTextOptions,
} from "@portabletext/to-html";
import { type RichTextModel } from "@britishredcross/kontent-integration/src/models";
import WebSpotlightConstants from "../../utils/web-spotlight";

const portableTextComponents: PortableTextOptions = {
	components: {
		marks: {
			link: ({
				children,
				value,
			}: PortableTextMarkComponentOptions<PortableTextExternalLink>) => {
				return `<a href=${value?.href!}>${children}</a>`;
			},
		},
	},
};

function RichText(props: RichTextModel, preview: boolean): JSX.Element {
	const parsedTree = nodeParse(props.elements.text.value);
	const portableText = transformToPortableText(parsedTree);

	let dataKontentElementCodename;
	let dataKontentItemId;

	if (preview) {
		dataKontentElementCodename = {
			[WebSpotlightConstants.dataKontentElementCodename]: props.system.codename,
		};
		dataKontentItemId = {
			[WebSpotlightConstants.dataKontentItemId]: props.system.id,
		};
	}

	const resolvedHtml = toHTML(portableText, portableTextComponents);

	return (
		<RichTextBrc {...dataKontentElementCodename} {...dataKontentItemId}>
			{resolvedHtml}
		</RichTextBrc>
	);
}

export default RichText;
