import React from "react";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { type BannerProps } from "./banner.types";

export function Banner({ text, isError = false }: BannerProps) {
	return (
		<div
			className={`flex items-center text-white font-bold px-4 py-4 text-sm md:text-base md:py-6 mb-8 md:mb-16 ${isError ? "bg-red-tint" : "bg-teal-primary"}`}
		>
			<div className="w-full flex items-center md:max-content md:mx-auto">
				<span className="mr-2 rtl:mr-0 rtl:ml-2">
					<Icon
						name={isError ? "error" : "schedule"}
						size={24}
						colour={IconColour.NeutralWhite}
					/>
				</span>
				<p className="text-white">{text}</p>
			</div>
		</div>
	);
}
