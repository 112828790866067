import React from "react";
import { Jotform as JotformBrc } from "@britishredcross/component-library/src/components/atoms/jotform/jotform";
import WebSpotlightConstants from "../../utils/web-spotlight";

function Jotform(props: any, preview: boolean) {
	let dataKontentElementCodename;
	let dataKontentItemId;
	if (preview) {
		dataKontentElementCodename = {
			[WebSpotlightConstants.dataKontentElementCodename]: props.system.codename,
		};
		dataKontentItemId = {
			[WebSpotlightConstants.dataKontentItemId]: props.system.id,
		};
	}

	return (
		<JotformBrc
			formId={props.elements.form_id.value}
			{...dataKontentElementCodename}
			{...dataKontentItemId}
		/>
	);
}

export default Jotform;
