import React from "react";
import { ContactDetail } from "@britishredcross/component-library/src/components/molecules/contact-detail/contact-detail";
import { ContentType } from "@britishredcross/component-library/src/components/molecules/contact-detail/contact-detail.types";
import { Heading } from "@britishredcross/component-library/src/components/atoms/heading/heading";
import { HeadingLevel } from "@britishredcross/component-library/src/components/atoms/heading/heading.types";
import { PlainText } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { BgColour } from "@britishredcross/component-library/src/components/organisms/contact-banner/contact-banner.types";
import { ContactBanner as ContactBannerBrc } from "@britishredcross/component-library/src/components/organisms/contact-banner/contact-banner";
import upperFirst from "lodash.upperfirst";
import { type ContactBannerModel } from "@britishredcross/kontent-integration/src/models";

export function ContactBanner(props: ContactBannerModel): JSX.Element {
	const contactDetails = props.elements.contact_details.linkedItems.map(
		(contactDetail) => (
			<ContactDetail
				content={contactDetail.elements.detail.value}
				contentType={
					ContentType[
						upperFirst(
							contactDetail.elements.type.value[0]?.name
						) as keyof typeof ContentType
					]
				}
				key={contactDetail.system.id}
				label={contactDetail.elements.label.value}
				supportingText={contactDetail.elements.supporting_text.value}
			/>
		)
	);

	return (
		<ContactBannerBrc
			bgColour={getBackgroundColour(
				props.elements.background_colour.value[0]?.codename ?? ""
			)}
			contactDetail={contactDetails}
			subtitle={<PlainText>{props.elements.subtitle.value}</PlainText>}
			title={
				<Heading level={HeadingLevel.H2}>{props.elements.title.value}</Heading>
			}
		/>
	);
}

function getBackgroundColour(colour: string): BgColour {
	switch (colour) {
		case "green":
			return BgColour.Green;
		case "blue":
			return BgColour.DarkBlue;
		case "teal":
			return BgColour.Teal;
		case "grey":
			return BgColour.Grey;
		default:
			return BgColour.DarkRed;
	}
}
