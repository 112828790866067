/* eslint-disable camelcase -- Kontent API uses snake_case for field names. Maintaining that here allows us to continue using the VideoModel type */

import React from "react";
import { Video as VideoBrc } from "@britishredcross/component-library/src/components/molecules/video/video";
import type { VideoModel } from "@britishredcross/kontent-integration/dist/esm/models";

export function Video(props: VideoModel): JSX.Element {
	const { video_id, caption, credit } = props.elements;

	return (
		<VideoBrc
			youtubeId={video_id.value}
			caption={caption.value}
			credit={credit.value}
		/>
	);
}
