import React, { Suspense } from "react";
import { SearchClient } from "./search-client";

export function Search(props): JSX.Element {
	return (
		// SearchClient makes use of the the useSearchParams hook to update the URL query string
		// This hook is not supported in Server Side components so we need to add this wrapping component
		// to wrap the SearchClient in a <Suspense>
		// 	https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
		<Suspense>
			<SearchClient searchContentItem={props} />
		</Suspense>
	);
}
