import React from "react";
import { type ShopDetailsPageProps } from "./shop-details-page.types";

export function ShopDetailsPage({
	hero,
	banner,
	shopContactDetails,
	shopOpeningHours,
	content,
}: ShopDetailsPageProps): JSX.Element {
	return (
		<div>
			<div className="[&_.brc-hero]:mb-0 [&_.brc-hero]:border-0">{hero}</div>
			{banner}
			{shopContactDetails}
			{shopOpeningHours}
			{content}
		</div>
	);
}
