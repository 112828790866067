import React from "react";
import { type MultiColumnLayoutProps } from "./multi-column-layout.types";

export function MultiColumnLayout({
	displayDivider,
	columnOne,
	columnTwo,
}: MultiColumnLayoutProps) {
	return (
		<section
			className={` w-full max-content mx-auto ${
				displayDivider ? "border-b border-grey-30" : ""
			} pb-4 mb-8 px-4 md:mb-16 lg:px-0 `}
		>
			<div className="grid grid-cols-1 gap-4 content-between md:grid-cols-2 md:gap-6">
				<div>
					{React.Children.map(columnOne, (child, key: number) => (
						<div className="brc-column-layout" key={key}>
							{child}
						</div>
					))}
				</div>
				<div>
					{React.Children.map(columnTwo, (child, key: number) => (
						<div className="brc-column-layout" key={key}>
							{child}
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
