import React from "react";
import { LinkType } from "@britishredcross/component-library/src/components/atoms/link/link.types";
import { Link as LinkBrc } from "@britishredcross/component-library/src/components/atoms/link/link";
import { getUrlFromMapping } from "@/utils/path";

function Link(props) {
	let url;
	let linkItem;
	if (props?.linkedItems?.length > 0) {
		linkItem = props?.linkedItems[0]?.elements;
	} else {
		linkItem = props.elements;
	}
	if (
		linkItem?.external_link?.value?.length > 0 &&
		!linkItem?.internal_link?.linkedItems[0]
	) {
		url = linkItem.external_link.value;
	} else {
		url = getUrlFromMapping(
			props.mappings,
			linkItem.internal_link.linkedItems[0].system.codename
		);
	}
	return (
		<LinkBrc destination={url} linkType={LinkType.Default}>
			{linkItem.display.value}
		</LinkBrc>
	);
}

export default Link;
