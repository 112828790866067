import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type CTABannerProps } from "./cta-banner.types";

export function CTABanner({ title, description, link }: CTABannerProps) {
	return (
		<section className=" bg-grey-10 px-4 py-6 mb-8 md:px-6 md:py-8 md:mb-12 lg:mb-16">
			<div className="mx-auto my-0 max-content grid gap-4 grid-cols-12 lg:gap-6">
				<div className="col-span-12 prose-p:mb-4 prose-h2:mb-2 prose-h2:md:mb-4 lg:col-span-8 lg:col-start-1">
					<Heading level={HeadingLevel.H2}>{title}</Heading>
					<PlainText size={PlainTextSize.Default}>{description}</PlainText>
					{link}
				</div>
			</div>
		</section>
	);
}
