import React from "react";
import { HeroPage as HeroPageBrc } from "@britishredcross/component-library/src/components/molecules/hero-page/hero-page";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";

function HeroPage(props) {
	const { image, subtitle, title, text } = props.linkedItems[0].elements;

	const imageElement = image.value.length ? (
		<ImageBrc
			alt={image?.value[0]?.description}
			src={image?.value[0]?.url}
			width="528"
			height="436"
		/>
	) : undefined;

	return (
		<HeroPageBrc
			image={imageElement}
			subTitle={subtitle?.value}
			text={text.value}
			title={title.value}
		/>
	);
}

export default HeroPage;
