import React from "react";

function OnetrustCookieList() {
	return (
		<div className="mx-auto w-full max-content p-4 md:p-6 lg:px-0">
			<div id="ot-sdk-cookie-policy" />
		</div>
	);
}

export default OnetrustCookieList;
