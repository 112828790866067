import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { Link } from "../../atoms/link/link";
import { HeadingLevel, LinkType } from "../../../types";
import { type LinkListProps } from "./link-list.types";

export function LinkList({
	linkListTitle,
	linkList,
	linkGroup,
}: LinkListProps) {
	return (
		<div className="max-content mx-auto">
			<div className="px-4 max-w-full mb-12 lg:mb-16 lg:px-0">
				{linkListTitle ? (
					<div className="prose prose-h2:mt-0 prose-h2:mb-6">
						<Heading level={HeadingLevel.H2}>{linkListTitle}</Heading>
					</div>
				) : null}

				{linkList ? (
					<ul className="list-none mb-6 md:mb-8">
						{Object.entries(linkList).map(([key, { links }]) =>
							links.map((link, index) => (
								<li className="mb-3" key={`${key}-${index}`}>
									<Link
										linkType={LinkType.Default}
										destination={link.destination}
									>
										{link.title}
									</Link>
								</li>
							))
						)}
					</ul>
				) : null}

				{linkGroup
					? Object.entries(linkGroup).map(([key, { title, links }]) => (
							<div key={key}>
								<div className="prose prose-h3:mt-0 prose-h3:mb-3 prose-h3:md:mb-4">
									<Heading level={HeadingLevel.H3}>{title}</Heading>
								</div>
								<ul className="list-none mb-6 md:mb-8">
									{links.map((link, linkKey) => (
										<li key={linkKey} className="mb-3">
											<Link
												linkType={LinkType.Default}
												destination={link.destination}
											>
												{link.title}
											</Link>
										</li>
									))}
								</ul>
							</div>
						))
					: null}
			</div>
		</div>
	);
}
