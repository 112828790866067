import React from "react";
import { XProfile as XProfileBrc } from "@britishredcross/component-library/src/components/molecules/x-profile/x-profile";
import type { XProfileModel } from "@britishredcross/kontent-integration/src/models/content-types/XProfile";

export function XProfile(props: XProfileModel): JSX.Element {
	return (
		<XProfileBrc
			title={props.elements.title.value}
			description={props.elements.description.value}
			xusername={props.elements.x_username.value}
		/>
	);
}
