"use client";
import { type PageModel } from "@britishredcross/kontent-integration/src/models/content-types";
import camelCase from "lodash.camelcase";
import upperFirst from "lodash.upperfirst";
import React, { useEffect, useState } from "react";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { useParams } from "next/navigation";
import { type IContentItem } from "@kontent-ai/delivery-sdk";
import { getErrorPage } from "@/data-access/server-actions/get-error-page";
import heroTypes from "@/components/hero";
import contentTypes, {
	type ContentComponents,
	type ContentTypes,
	contentTypeExists,
} from "@/components/";
import { getSitemapMappings } from "@/data-access/server-actions/get-sitemap-mappings";
import type HomepageHero from "@/components/hero/homepage-hero";
import type HeroPage from "@/components/hero/hero-page";

// eslint-disable-next-line import/no-default-export -- This is necessary because Next.js requires default exports for special pages like error.tsx.
export default function ErrorPage(): JSX.Element {
	const [errorData, setErrorData] = useState<PageModel | null>(null);

	// eslint-disable-next-line react/hook-use-state -- The variable contains a component, not a state, so the name can't be lowercase because to use it as a component, it needs to be capitalized.
	const [Hero, setHero] = useState<
		typeof HomepageHero | typeof HeroPage | null
	>(null);
	const [mappings, setMappings] = useState<SitemapMapping[] | null>(null);

	const { lang } = useParams<{ lang: string }>();

	useEffect(() => {
		const fetchErrorData = async (): Promise<void> => {
			try {
				const [errorPage, sitemapMappings] = await Promise.all([
					getErrorPage(lang),
					getSitemapMappings(lang),
				]);

				setErrorData(errorPage);
				setMappings(sitemapMappings);

				if (errorPage) {
					const heroType = upperFirst(
						camelCase(errorPage.elements.hero.linkedItems[0]?.system.type)
					);
					// take the type of Hero component from Kontent.ai
					// match it to one from the component library
					// set it in the state so a re-render is triggered to display the appropriate hero (from hero/index.ts)
					const HeroComponent = heroTypes[heroType as keyof typeof heroTypes];
					setHero(() => HeroComponent);
				}
			} catch (error) {
				// eslint-disable-next-line no-console -- Replace with Sentry in future
				console.error("Error fetching error page data:", error);
			}
		};

		void fetchErrorData();
	}, [lang]);

	return (
		<main id="main">
			{Hero ? <Hero {...errorData?.elements.hero} /> : null}

			{errorData && mappings
				? errorData.elements.content.linkedItems
						.filter((item: IContentItem) =>
							contentTypeExists(upperFirst(camelCase(item.system.type)))
						)
						.map((item: IContentItem, index: number) => {
							const ContentComponent: ContentComponents =
								contentTypes[
									upperFirst(camelCase(item.system.type)) as keyof ContentTypes
								];
							// More than one component may be used on the same page, which would have the same ID, so we're adding index to make it more unique:
							const componentKey = `${item.system.id}-${index.toString()}`;

							return (
								<ContentComponent
									{...item}
									key={componentKey}
									mappings={mappings}
									draftMode={false}
								/>
							);
						})
				: null}
		</main>
	);
}
