import React from "react";
import { Accordion as AccordionBrc } from "@britishredcross/component-library/src/components/molecules/accordion/accordion";
import type { AccordionModel } from "@britishredcross/kontent-integration/dist/esm/models";

export function Accordion(props: AccordionModel): JSX.Element {
	const { sections, title } = props.elements;

	return (
		<AccordionBrc
			title={title.value}
			sections={sections.linkedItems.map((section) => ({
				sectionTitle: section.elements.title.value,
				sectionContent: section.elements.body.value,
			}))}
		/>
	);
}
