import React from "react";
import { MultiColumnLayout as MultiColumnLayoutBrc } from "@britishredcross/component-library/src/components/organisms/multi-column-layout/multi-column-layout";
import camelCase from "lodash.camelcase";
import upperFirst from "lodash.upperfirst";
import contentTypes from "../index";

function MultiColumnLayout(props) {
	let displayDivider = false;
	// Check if the divider prop is available
	if (props.elements.show_bottom_border?.value[0]?.codename) {
		const divider = props.elements.show_bottom_border.value[0]?.codename;
		displayDivider = JSON.parse(divider); // Assign the parsed value to displayDivider
	}

	const column1 = props.elements.column_1.linkedItems.map((item, index) => {
		const ContentItem = contentTypes[upperFirst(camelCase(item.system.type))];
		return <ContentItem key={index.id} {...item} mappings={props.mappings} />;
	});
	const column2 = props.elements.column_2.linkedItems.map((item, index) => {
		const ContentItem = contentTypes[upperFirst(camelCase(item.system.type))];
		return <ContentItem key={index.id} {...item} mappings={props.mappings} />;
	});

	return (
		<MultiColumnLayoutBrc
			columnOne={column1}
			columnTwo={column2}
			displayDivider={displayDivider}
		/>
	);
}

export default MultiColumnLayout;
