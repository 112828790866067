import React from "react";
import { Link } from "../../atoms/link/link";
import { LinkType } from "../../../types";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type ContactDetailProps } from "./contact-detail.types";

export function ContactDetail({
	label,
	contentType,
	content,
	supportingText,
}: ContactDetailProps) {
	return (
		<div className="w-full px-0 md:px-2.5">
			<div className="mb-0 prose-p:text-white md:mb-3">
				<PlainText size={PlainTextSize.Default}>{label}</PlainText>
			</div>
			<div className="prose-a:whitespace-pre-wrap prose-a:break-words">
				{contentType === "email" ? (
					<Link
						linkType={LinkType.DefaultDarkBg}
						destination={`mailto:${content}`}
					>
						{content}
					</Link>
				) : contentType === "phone" ? (
					<bdi>
						<Link
							linkType={LinkType.DefaultDarkBg}
							destination={`tel:${content}`}
						>
							{content}
						</Link>
					</bdi>
				) : contentType === "address" ? (
					<bdi className="prose-p:text-white prose-p:font-bold rtl:text-right">
						<PlainText size={PlainTextSize.Large}>{content}</PlainText>
					</bdi>
				) : contentType === "link" ? (
					<Link linkType={LinkType.DefaultDarkBg} destination={content}>
						{content}
					</Link>
				) : null}
				{/* TODO should we have the option for just a link URL? */}
			</div>
			{supportingText ? (
				<div className="mt-0 prose-p:text-white md:mt-2">
					<PlainText size={PlainTextSize.Sm}>{supportingText}</PlainText>
				</div>
			) : null}
		</div>
	);
}
