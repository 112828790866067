import React from "react";
import { FileList as FileListBrc } from "@britishredcross/component-library/src/components/molecules/file-list/file-list";

function FileList(props: any) {
	const fileListDetails = props.elements.files.linkedItems.map(
		(fileDetail) => ({
			fileLinks: [
				{
					title: fileDetail.system.name,
					destination: fileDetail.elements.file.value[0].url,
				},
			],
			fileFormat: fileDetail.elements.file.value[0].type
				.split("/")[1]
				.toUpperCase(),
			fileSize: bytesToSize(fileDetail.elements.file.value[0].size),
		})
	);

	return (
		<FileListBrc
			downloadLinks={fileListDetails}
			fileListTitle={props.elements.title.value}
		/>
	);
}
export default FileList;

export function bytesToSize(bytes: number): string {
	const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "n/a";
	const i: number = parseInt(
		Math.floor(Math.log(bytes) / Math.log(1024)).toString()
	);
	if (i === 0) return `${bytes} ${sizes[i]}`;
	return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}
