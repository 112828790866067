import React from "react";
import { ShopOpeningHours as ShopOpeningHoursBrc } from "@britishredcross/component-library/src/components/molecules/shop-opening-hours/shop-opening-hours";
import { ShopDetailsPage as ShopDetailsPageBrc } from "@britishredcross/component-library/src/components/templates/shop-details-page/shop-details-page";
import { HeroPage as HeroPageBrc } from "@britishredcross/component-library/src/components/molecules/hero-page/hero-page";
import { Banner as BannerBrc } from "@britishredcross/component-library/src/components/molecules/banner/banner";
import { ShopContactDetails as ShopContactDetailsBrc } from "@britishredcross/component-library/src/components/molecules/shop-contact-details/shop-contact-details";
import type { ShopModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { type DictionaryModel } from "@britishredcross/kontent-integration/src/models";

interface ShopDetailsProps extends ShopModel {
	contentItems: React.ReactNode[];
	dictionaryData: DictionaryModel;
}

function ShopDetails(props: ShopDetailsProps): JSX.Element {
	const pageProps = props.elements;
	const isTemporarilyClosed =
		pageProps.shop_status_override.value[0]?.codename === "temporarily_closed";

	const closedLabel = props.dictionaryData.elements.opening_hours_close.value;

	const setBannerText = (): string => {
		if (isTemporarilyClosed)
			return props.dictionaryData.elements.banner_temporarily_closed_text.value;
		const today = new Date();
		const day = today
			.toLocaleDateString(undefined, { weekday: "long" })
			.toLowerCase();
		if (
			[
				"monday",
				"tuesday",
				"wednesday",
				"thursday",
				"friday",
				"saturday",
				"sunday",
			].includes(day)
		) {
			const todaysOpeningHours = pageProps[`${day}_open_hours`]?.value;
			return todaysOpeningHours
				? `${props.dictionaryData.elements.banner_open_text.value} ${pageProps[`${day}_open_hours`].value}`
				: props.dictionaryData.elements.banner_closed_text.value;
		}
		return "";
	};

	const hero = (
		<HeroPageBrc
			title={pageProps.hero_title.value}
			text={pageProps.hero_text.value}
		/>
	);

	const banner = (
		<BannerBrc isError={isTemporarilyClosed} text={setBannerText()} />
	);

	const shopContactDetails = (
		<ShopContactDetailsBrc
			address={pageProps.address.value}
			mapAddress={pageProps.map__static__address.value}
			phoneNumber={pageProps.phone_number.value}
			emailAddress={pageProps.email.value}
			facebookLink={pageProps.facebook_link.value}
			shopContactDetailsLabel={
				props.dictionaryData.elements.shop_contact_details_label.value
			}
			addressLabel={props.dictionaryData.elements.address_label.value}
			phoneNumberLabel={props.dictionaryData.elements.phone_number_label.value}
			emailAddressLabel={props.dictionaryData.elements.email_label.value}
			facebookLinkLabel={props.dictionaryData.elements.facebook_link_text.value}
			socialMediaLabel={props.dictionaryData.elements.facebook_link_label.value}
		/>
	);

	const shopOpeningHours = (
		<ShopOpeningHoursBrc
			monday={pageProps.monday_open_hours.value || closedLabel}
			tuesday={pageProps.tuesday_open_hours.value || closedLabel}
			wednesday={pageProps.wednesday_open_hours.value || closedLabel}
			thursday={pageProps.thursday_open_hours.value || closedLabel}
			friday={pageProps.friday_open_hours.value || closedLabel}
			saturday={pageProps.saturday_open_hours.value || closedLabel}
			sunday={pageProps.sunday_open_hours.value || closedLabel}
			mondayLabel={props.dictionaryData.elements.monday_label.value}
			tuesdayLabel={props.dictionaryData.elements.tuesday_label.value}
			wednesdayLabel={props.dictionaryData.elements.wednesday_label.value}
			thursdayLabel={props.dictionaryData.elements.thursday_label.value}
			fridayLabel={props.dictionaryData.elements.friday_label.value}
			saturdayLabel={props.dictionaryData.elements.saturday_label.value}
			sundayLabel={props.dictionaryData.elements.sunday_label.value}
			openingHoursLabel={
				props.dictionaryData.elements.opening_hours_label.value
			}
			temporarilyClosedLabel={
				props.dictionaryData.elements.banner_temporarily_closed_text.value
			}
			openingHoursNote={pageProps.open_hours_supporting_text.value}
			isTemporarilyClosed={isTemporarilyClosed}
		/>
	);

	return (
		<ShopDetailsPageBrc
			banner={banner}
			hero={hero}
			shopContactDetails={shopContactDetails}
			shopOpeningHours={shopOpeningHours}
			content={<>{props.contentItems}</>}
		/>
	);
}

export default ShopDetails;
