import React from "react";
import type { LinkListModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { LinkList as LinkListBrc } from "@britishredcross/component-library/src/components/molecules/link-list/link-list";

export interface GeneratedLink {
	title: string;
	destination: string;
}

export const generateLinks = (items): GeneratedLink[] => {
	const generatedLinks: GeneratedLink[] = [];
	if (Array.isArray(items)) {
		items.forEach((item) => {
			generatedLinks.push({
				title: item.elements.display.value,
				destination: item.elements.external_link.value,
			});
		});
	}
	return generatedLinks;
};

function LinkList(props: LinkListModel): JSX.Element {
	return (
		<LinkListBrc
			linkList={{
				linkList1: {
					links: generateLinks(props.elements.links.linkedItems),
				},
			}}
			linkListTitle={props.elements.title.value}
		/>
	);
}

export default LinkList;
