import React from "react";
import type { MapStaticModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { MapStatic as MapStaticBrc } from "@britishredcross/component-library/src/components/molecules/map-static/map-static";

function MapStatic(props: MapStaticModel): JSX.Element {
	const address = props.elements.address.value;

	return <MapStaticBrc address={address} />;
}

export default MapStatic;
