import type React from "react";
import ShopDetails from "./templates/shop-details";
import { CardContainer } from "./content/card-container";
import { ContactBanner } from "./content/contact-banner";
import Sitemap from "./content/sitemap";
import Image from "./content/image";
import FileList from "./content/file-list";
import Jotform from "./content/jotform";
import Link from "./content/link";
import MultiColumnLayout from "./content/multi-column-layout";
import OnetrustCookieList from "./content/onetrust-cookie-list";
import RichText from "./content/rich-text";
import SearchBox from "./content/search-box";
import VadRecord from "./content/vad-record";
import VadSearch from "./content/vad-search";
import LinkList from "./content/link-list";
import LinkListWithGroups from "./content/link-list-with-groups";
import CtaBanner from "./content/cta-banner";
import { Video } from "./content/video";
import { Accordion } from "./content/accordion";
import MapStatic from "./content/map-static";
import { XProfile } from "./content/x-profile";
import { Search } from "./search/search";
import Quote from "./content/quote";
// New import

export interface ContentTypes {
	CardContainer: React.ElementType;
	ContactBanner: React.ElementType;
	Jotform: React.ElementType;
	FileList: React.ElementType;
	Link: React.ElementType;
	LinkList: React.ElementType;
	RichText: React.ElementType;
	Image: React.ElementType;
	VadSearch: React.ElementType;
	VadRecord: React.ElementType;
	SearchBox: React.ElementType;
	Sitemap: React.ElementType;
	OnetrustCookieList: React.ElementType;
	MultiColumnLayout: React.ElementType;
	LinkListWithGroups: React.ElementType;
	CtaBanner: React.ElementType;
	Video: React.ElementType;
	Accordion: React.ElementType;
	MapStatic: React.ElementType;
	XProfile: React.ElementType;
	ShopDetails: React.ElementType;
	Search: React.ElementType;
	Quote: React.ElementType;
	// New ContentType
}

export type ContentComponents =
	| typeof CardContainer
	| typeof ContactBanner
	| typeof Jotform
	| typeof FileList
	| typeof Link
	| typeof RichText
	| typeof Image
	| typeof VadSearch
	| typeof VadRecord
	| typeof SearchBox
	| typeof Sitemap
	| typeof OnetrustCookieList
	| typeof MultiColumnLayout
	| typeof LinkList
	| typeof LinkListWithGroups
	| typeof CtaBanner
	| typeof Video
	| typeof Accordion
	| typeof MapStatic
	| typeof XProfile
	| typeof ShopDetails
	| typeof Search
	| typeof Quote;
// New Content Component

export const Content = {
	CardContainer,
	ContactBanner,
	Jotform,
	FileList,
	Link,
	RichText,
	Image,
	VadSearch,
	VadRecord,
	SearchBox,
	Sitemap,
	OnetrustCookieList,
	MultiColumnLayout,
	LinkList,
	LinkListWithGroups,
	CtaBanner,
	Video,
	Accordion,
	MapStatic,
	XProfile,
	ShopDetails,
	Search,
	Quote,
	// New Content
};

export function contentTypeExists(contentType: string): boolean {
	return contentType in Content;
}

export default Content;
