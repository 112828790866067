import HomepageHero from "./homepage-hero";
import HeroPage from "./hero-page";

export interface HeroTypes {
	HomepageHero: typeof HomepageHero;
	HeroPage: typeof HeroPage;
}

// one of these components will be returned based on the type of hero in Kontent.ai, e.g. in the error page (apps/nextjs/app/[lang]/[[...slug]]/error.tsx)
const Heroes = { HomepageHero, HeroPage };

export default Heroes;
