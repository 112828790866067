import React from "react";
import { IconColour } from "@britishredcross/component-library/src/components/atoms/icon/icon.types";
import { Icon } from "../icon/icon";
import { RichText } from "../../atoms/rich-text/rich-text";
import { PlainText } from "../../atoms/plain-text/plain-text";
import { PlainTextSize } from "../../atoms/plain-text/plain-text.types";
import { type QuoteProps } from "./quote.types";

// desktop layout
// multi-column layout

export function Quote({
	children,
	attribution,
}: QuoteProps): React.JSX.Element {
	return (
		<div className="brc-quote flex max-content mx-auto px-4 mb-8 md:mb-12 lg:px-0 lg:mb-16">
			<div>
				<Icon name="quote" size={25} colour={IconColour.PrimaryRed} />
			</div>
			<div className="pl-2.5 mt-2 [&_.brc-richtext]:mb-0 [&_.brc-richtext]:px-0">
				<blockquote className="font-bold">
					<RichText>{children}</RichText>
				</blockquote>
				{attribution ? (
					<PlainText size={PlainTextSize.Default}>{attribution}</PlainText>
				) : null}
			</div>
		</div>
	);
}
